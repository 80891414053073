import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../../components/hero";
import { BsFillCheckCircleFill } from "react-icons/bs";

const TGPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "About-Us-hero-image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "TG" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Tamsin Gwynn",
				item: {
					url: `${siteUrl}/about-us/tamsin-gwynn`,
					id: `${siteUrl}/about-us/tasmin-gwynn`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Tamsin Gwynn | The Facial Rehabilitation Centre"
				description=""
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us/tamsin-gwynn`,
					title: "Tamsin Gwynn | The Facial Rehabilitation Centre",
					description: "",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						subTitle="Home > About us > Tamsin Gwynn"
						title="Tamsin Gwynn"
						backgroundImage={
							data.heroImg?.localFile.childImageSharp.gatsbyImageData
						}
					/>
				</section>
				<section className="position-relative py-5 pt-md-0 pb-lg-7">
					<Container>
						<Row>
							<Col
								style={{ top: "-12rem" }}
								className="position-absolute d-none d-xl-block "
								lg={3}
							>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
						</Row>
						<Row className="  ps-xl-7 justify-content-xl-end">
							<Col className=" d-xl-none pb-5 pb-lg-0 " lg={4}>
								<div>
									<GatsbyImage
										style={{
											maxWidth: "100%",
										}}
										image={data.img1.localFile.childImageSharp.gatsbyImageData}
										alt={data.img1.altText}
									/>
								</div>
							</Col>
							<Col lg={7} xl={8}>
								<p>
									Tamsin Gwynn BSc (Hons) mCSP, mHCPC trained at the University
									of Hertfordshire, qualifying in 2006. Having completed junior
									rotations in a Kent NHS Trust, Tamsin went on to work in
									private and independent hospitals before joining Queen
									Victoria Hospital in January 2013 as part of the specialist
									Facial Palsy Team.
								</p>
								<p>
									{" "}
									Since joining the team Tamsin has become and independent
									non-medical prescriber and Chemo denervation practitioner.
									Tamsin is a committee member of the Facial Therapy Specialist
									International, being a member of the teaching committee
									assisting on delivering the training courses through FTS-UK .
								</p>
								<p>
									In 2017 Tamsin along with the other QVH team attended and
									presented at the International facial nerve symposium. Over
									the past few years, Tamsin has participated with the team on
									many research projects. The team are currently preparing many
									presentations for the upcoming International Facial Nerve
									symposium hosted by South Korea.
								</p>
							</Col>
						</Row>
					</Container>
					<div className="d-none d-xl-block" style={{ height: "11rem" }}></div>
				</section>

				{/* <section className=" pb-5 pb-lg-7 pb-xl-7 pt-xl-9">
					<Container>
						<Row>
							<Col className="text-center">
								<h2 className="text-dark-bg pb-4 ">Experience</h2>
							</Col>
						</Row>
						<Row className="pt-5">
							<Col className="pb-5 pb-lg-0" lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Et harum quidem rerum facilis est et expedita distinctio
								</p>
							</Col>
							<Col className="pb-5 pb-lg-0" lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Et harum quidem rerum facilis est et expedita distinctio
								</p>
							</Col>
							<Col lg={4}>
								<BsFillCheckCircleFill className="text-secondary fs-2 mb-3" />
								<p className=" ubuntu-bold fs-5">
									Et harum quidem rerum facilis est et expedita distinctio
								</p>
							</Col>
						</Row>
					</Container>
				</section> */}
				{/* <section className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row>
							<Col className="mb-5 mb-lg-0" lg={6}>
								<h2 className="text-dark-bg pb-4 ">Qualifications</h2>
								<p>· Quis autem vel eum iure reprehenderit</p>
								<p>· Quis autem vel eum iure reprehenderit</p>
								<p>· Quis autem vel eum iure reprehenderit</p>
								<p>· Quis autem vel eum iure reprehenderit</p>
							</Col>
							<Col lg={6}>
								<h2 className="text-dark-bg pb-4 ">Professional Memberships</h2>
								<p>. Temporibus autem quibusdam</p>
								<p>. Temporibus autem quibusdam</p>
								<p>. Temporibus autem quibusdam</p>
								<p>. Temporibus autem quibusdam</p>
								<p>. Temporibus autem quibusdam</p>
							</Col>
						</Row>
					</Container>
				</section>
				<section className="py-5 py-lg-7">
					<Container>
						<Row>
							<Col>
								<h2 className="text-dark-bg pb-5 text-center ">
									Credentials & Employment History
								</h2>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
								<p>
									. Itaque earum rerum hic tenetur a sapiente delectus, ut aut
									reiciendis voluptatibus maiores alias consequature
								</p>
							</Col>
						</Row>
					</Container>
				</section> */}
			</Layout>
		</>
	);
};

export default TGPage;
